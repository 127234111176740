.login-section {
    padding: 60px 0;
  }
  
  .login__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 10px rgba(17, 12, 46, 0.15);
    margin-top: 100px;
  }
  .login__container .user{
    width: 80px;
    height: 80px;
    position: absolute;
    top: -9%;
    left: 50%;
    transform: translate(-50%, 9%);
  }
  .login__img{
    width: 60%;
    text-align: center;
  }
  .login__img img {
    max-width: 70%;
    object-fit: contain;
  }
  
  .login__form {
    background: var(--secondary-color);
    padding: 50px;
    position: relative;
    border-radius: 5px;
    width: 40%;
  }
  
  .login__form input{
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: none;
    font-size: 1rem;
    color: var(--heading-color);
  }
  .login__form input:focus{
    outline: none;
  }
  .user img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
  
  .login__form h2 {
    color: #fff;
    text-align: center;
    font-size: 24px;
    margin-bottom: 2rem;
  }
  
  form {
    margin-bottom: 20px;
  }
  
  .login__form p {
    font-size: .9rem;
    margin-top: 1.1rem;
    color: #fff;
    text-align: center;
  }
  
  .login__form p a {
    color: var(--heading-color);
    text-decoration: none;
    margin-left: 5px;
    font-weight: 500;
    color: #fff;
  }

  .login__form label{
    color: #fff;
    font-weight: 500;
  }
  .login__form p a:hover{
    color: red;
  }
  .auth__btn {
    margin-top: 20px;
    background: var(--heading-color) !important;
    width: 100%;
    color: #fff !important;
  }
  
  .auth__btn:focus {
    box-shadow: none;
  }
  
  @media (max-width: 768px) {
    .login__container {
      flex-direction: column;
      align-items: center;
    }
  
    .login__img {
      margin-bottom: 40px;
    }
  
    .login__form {
      width: 100%;
    }
  }
  
  @media only screen and (max-width:768px) {
      .login__img{
        display: none;
      }

      .login__form {
        width: 70%;
        margin: auto;
      }

      .login__container {
        padding-bottom: 3rem;
        margin-top: 70px;
      }
  }

  @media only screen and (max-width:576px){
    .login__form {
      width: 100%;
    }
    .login__container {
      padding-bottom: 1rem;
    }
  }