.about-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background:  #cae5f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .about-heading {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-paragraph {
    font-size: 18px;
    line-height: 1.6;
    color: var(--heading-color);
    margin-bottom: 10px;
  }
  