.booking {
    padding: 2rem;
    background-color:#fff;
    border-radius: .5rem;
    border: 1px solid;
    position: sticky;
    top: 80px;
  }
  
  .booking__top h3 {
    font-style: 2rem;
    font-weight: 700;
  }
  
  .booking__top h3 span {
    font-size: 1.1rem;
    font-weight: 400;
    color: #999;
  }

  
  .booking .tour__rating {
    margin-left: 10px;
  }
  
  .booking .tour__rating i {
    margin-right: 2px;
    color: gold;
  }
  
  .booking__form {
    margin-top: 20px;
  }
  
  .booking__form h5 {
    margin-bottom: 10px;
  }

  .booking__info-form{
    padding: 1rem;
    border: 1px solid  rgb(229, 231, 235);
  }
  
  
  .booking__form input{
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: var(--heading-color);
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid rgb(229, 231, 235);
  }

  .booking__form input:focus{
    outline: none;
  }
  
  .booking__info-form .gap-3 input:first-child {
    margin-right: 10px;
  }
  
  .booking__info-form input[type="date"] {
    padding: 6px;
  }
  
  .booking__info-form input[type="number"] {
    width: 100%;
  }
  
  .booking__info-form input[type="submit"] {
    margin-top: 10px;
  }
  
  .booking__bottom li{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .booking__bottom li h5, 
  .booking__bottom li span{
    font-size: 1rem;
    color: var(--text-color);
    font-weight: 500;
  }

  .booking__bottom{
    margin-top: 1rem;
  }

  