.tour__card {
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .tour__card:hover {
    transform: translateY(-5px);
  }
  
  .tour__img {
    position: relative;
    height: 200px;
    overflow: hidden;
  }
  
  .tour__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
    transition: opacity 0.3s ease;
  }
  
  .tour__img span {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    font-size: 0.8rem;
  }
  
  .tour__card:hover .tour__img img {
    opacity: 0.8;
  }
  
  .card__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: var(--secondary-color);
    color: #fff;
  }
  
  .tour__location {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.9rem;
  }
  
  .tour__rating {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
  }
  
  .tour__rating i {
    color: #ffd700;
  }
  
  .tour__title {
    padding: 1rem;
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  .tour__title a {
    color: var(--heading-color);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .tour__title a:hover {
    color: var(--secondary-color);
  }
  
  .card__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
  }
  
  .card__bottom h5 {
    font-size: 0.8rem;
    font-weight: 700;
    color: var(--secondary-color);
  }
  
  .booking__btn {
    background: var(--secondary-color) !important;
    border: none;
    cursor: pointer;
    padding: 0.2rem 0.5rem !important;
    border-radius: 3px;
    transition: background-color 0.3s ease;
    
  }

  .booking__btn a{
    text-decoration: none;
    font-size: 0.9rem;
    color: #fff;
  }
  
  .booking__btn a:hover {
    background-color: var(--secondary-color-dark);
    color: red;
  }
  
  @media only screen and (max-width:576px) {
    .tour__title {
      font-size: 1rem;
    }

    .booking__btn a {
      font-size: 0.8rem;
    }
    .booking__btn {
      padding: 0.2rem 0.5rem !important;
    }

    .tour__location,
    .tour__rating {
      font-size: 0.8rem;
    }
  }